<template>
  <div class="pages">
    <div class="task_box">
      <div class="table_boxs">
        <el-table
          tooltip-effect="dark"
          stripe
          :data="tableList"
          style="width: 100%"
          :row-key="(row) => row.user_id + '-' + row.pid"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        >
          <el-table-column
            align="center"
            prop="username"
            label="用户名"
            min-width="100"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="name"
            label="所属组织"
            min-width="100"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="createtime"
            label="创建日期"
            min-width="120"
          ></el-table-column>

          <el-table-column
            label="操作"
            align="center"
            fixed="right"
            width="160"
          >
            <template slot-scope="scope">
              <div class="handle_box">
                <div
                  class="handle_btn edit"
                  @click="handleEdit(scope.row.user_id)"
                >
                  编辑
                </div>
                <div
                  class="handle_btn"
                  @click="handleDelete(scope.row.user_id)"
                >
                  删除
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页 -->
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :current-page="page"
          @current-change="handleCurrentChange"
          next-text="下一页"
          prev-text="上一页"
        ></el-pagination>
        <!-- 添加设备弹出层 -->
        <el-dialog
          title="编辑账号"
          :visible.sync="dialogFormVisible"
          width="500px"
        >
          <el-form :model="addInfo">
            <el-form-item label="所属项目:" label-width="100px">
              <el-cascader
                :options="organizeList"
                :show-all-levels="false"
                @change="getProjectVal"
                v-model="addInfo.website_id"
                :placeholder="addInfo.placeholder"
                :props="customProps"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="用户名: " label-width="100px">
              <el-input
                v-model="addInfo.username"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="修改密码：" label-width="100px">
              <el-input
                v-model="addInfo.password"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="addFacility()">确 定</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      multipleSelection: [],
      // 表格列表
      tableList: [],
      page: 1,
      // 总数据条数
      total: 0,
      // 弹框
      dialogFormVisible: false,
      addPopupsTitle: "",
      addInfo: {
        website_id: "",
        placeholder: "请选择组织",
        username: "",
        password: "",
      },
      organizeList: [],
      siteList: [],
      typeList: [],
      customProps: {
        children: "children",
        value: "id",
        label: "title",
      },
      zuIdname: "",
      organize_id: "",
      zname: "",
      password: "",
      role: "",
      group_id: "", // 角色id
      roleList: [],
      infoId: "",
    };
  },
  mounted() {
    // 获取表格列表数据
    this.getTableList();
    this.getTypeList();
    // // 获取 筛选类型选择项列表
    this.getzuzhiList();
    // // 页面初始化 获取 组织项目列表
  },
  methods: {
    getProjectVal() {},
    close() {
      this.dialogFormVisible = false;
    },
    // 获取 表格列表数据
    async getTableList() {
      const res = await this.$http.post("user");
      if (res.code !== 1) return this.$message.error("获取设备列表失败！");
      this.$message.success(res.msg);
      this.tableList = res.data.list;
      this.total = res.data.total;
    },
    // 分页
    handleCurrentChange(newPage) {
      console.log(newPage, "newPage");
      this.page = newPage;
      this.getTableList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 修改
    async handleEdit(id) {
      console.log(id);
      this.infoId = id;
      const res = await this.$http.post("user/info", {
        user_id: id,
      });
      if (res.code !== 1) return this.$message.error("获取设备列表失败！");
      console.log(res, "ffffffff");
      this.addInfo.placeholder = res.data.website;
      this.addInfo.website_id = res.data.website_id;
      this.addInfo.username = res.data.username;
      this.dialogFormVisible = true;
      this.getzuzhiList();
    },
    async addFacility() {
      const res = await this.$http.post("user/profile", {
        username: this.addInfo.username,
        password: this.addInfo.password,
        user_id: this.infoId,
        website_id: this.addInfo.website_id,
      });
      if (res.code !== 1) return this.$message.error(res.msg);
      this.$message.success(res.msg);
      this.getTableList();
      this.dialogFormVisible = false;
    },

    // 角色获取
    async getTypeList() {
      const res = await this.$http.post("user/user_group");
      this.roleList = res.data.list;
    },
    changeRole(e) {
      console.log(e, "ee");
      this.group_id = e;
    },
    // 组织 获取
    async getzuzhiList() {
      const res = await this.$http.post("Organize");
      this.organizeList = res.data;
      console.log(this.organizeList, " this.organizeList");
    },
    getProjectVal(data, checked, indeterminate) {
      console.log(data, "左侧二级菜单");
      // if(data.id) {
      this.addInfo.website_id = data[data.length - 1];

      // }
    },

    // 删除
    handleDelete(id) {
      this.$confirm("您确定要删除此账号吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await this.$http.post("user/del", { user_id: id });
          if (res.code !== 1) return this.$message.error(res.msg);
          this.$message.success(res.msg);
          this.getTableList();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-table__expand-icon {
  color: #fff;
  font-size: 14px;
}
::v-deep .el-table__expanded-cell {
  background-color: #32477d !important;
}
::v-deep.el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td.el-table__cell {
  background-color: #32477d !important;
}
::v-deep .el-table__row:hover {
  background-color: #32477d !important;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.pages {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.task_box {
  width: calc(100% - 220px);
}
.table_boxs::v-deep {
  position: relative;

  // tab表格样式
  .el-table {
    width: 100%;
  }
  .el-table__body tr.hover-row > td.el-table__cell {
    background-color: #32477d;
  }
  .el-table tr {
    background-color: #121d3c;
  }
  .el-table {
    color: #ffffff;
  }
  .el-table thead {
    color: #68d8fe;
  }
  .el-tabs__content {
    overflow: unset !important;
  }
  .el-table-column--selection {
    padding: 16px 10px !important;
  }
  .el-table .has-gutter .el-table__cell,
  .el-table th.el-table__cell {
    background-color: #213058;
  }
  .el-table .el-table__cell {
    // padding: 16px 0;
    border: 0;
  }
  .el-table::before,
  .el-table__fixed-right::before {
    height: 0;
  }
  tr.el-table__row--striped td.el-table__cell {
    background-color: #213058;
  }
  .handle_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .handle_btn {
      height: 26px;
      line-height: 26px;
      width: 50px;
      border-radius: 5px;
      border: 1px solid #61cdf1;
      font-size: 13px;
      cursor: pointer;
    }
    .edit {
      background-color: #61cdf1;
    }
  }
  // 分页
  .el-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    .el-pager li {
      font-size: 12px;
      border: 1px solid #61cdf1;
      font-weight: 500;
      color: #ffffff;
      background-color: #1b284d;
      padding: 0;
      height: 32px;
      line-height: 32px;
      min-width: 32px;
      margin: 0 7px;
    }
    .btn-next,
    .btn-prev {
      border: 1px solid #61cdf1;
      font-weight: 500;
      color: #ffffff;
      background-color: #1b284d;
      height: 32px;
      line-height: 32px;
      padding: 0 10px;
    }
    .btn-next span,
    .btn-prev span {
      font-size: 12px;
      height: 32px;
      line-height: 32px;
    }
  }
}
.dialog {
  // position: relative;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  .close {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    img {
      width: 36px;
      height: 36px;
    }
  }
  .zuBg {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
    // height: 643px;
  }
  .btnBg {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    width: 1111px;
    height: 806px;
    background: url("../../assets/images/editDialog.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
  }
  .btnBg .title {
    font-size: 36px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #68d8fe;
    margin-top: 57px;
    margin-bottom: 57px;
  }
  .inputList {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    border: 2px solid #61cdf1;
    border-radius: 4px;
    margin-top: 13px;
    width: 610px;
    height: 50px;
    margin-bottom: 40px;
    .icon {
      width: 26px;
      height: 24px;
    }
    padding: 0 40px;
    box-sizing: border-box;
  }
  .iptTitle {
    width: 100px;
    margin-left: 16px;
    display: flex;
    justify-content: flex-start;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    margin-right: 30px;
  }
  input {
    height: 52px;
    font-size: 20px;
    outline: none;
    border: none;
  }
  .inp input {
    background: rgba(0, 0, 0, 0);
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #cccccc;
  }
  .inputList .el-select > .el-input {
    border: none !important;
    height: 56px;
    background: rgba(0, 0, 0, 0);
  }
  .el-select {
    width: 300px !important;
    background: rgba(0, 0, 0, 0) !important;
    border: none !important;
  }
  .el-cascader {
    cursor: pointer;
    width: 300px;
  }
  ::v-deep .el-input__inner {
    margin-top: 2px;
    font-size: 20px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #666 !important;
    height: 52px;
    border: none;
    width: 420px !important;
    padding-left: 0;
    background: rgba(0, 0, 0, 0) !important;
    border: none !important;
  }
  .inputList .el-select .el-input .el-select__caret {
    font-size: 26px !important;
  }
  .inputList .el-input__icon {
    line-height: 51px !important;
  }
  .tijiao {
    width: 160px;
    height: 50px;
    background: #68d8fe;
    border-radius: 4px;
    font-size: 29px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #ffffff;
    text-align: center;
    line-height: 50px;
    margin-top: 29px;
  }
  .close {
    position: absolute;
    top: 86px;
    right: 68px;
    width: 36px;
    height: 36px;
    z-index: 1000000;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
